import axios from '@/config/httpConfig'

// 获取form列表
export function getFormList(data) {
  return axios.post('/form/getFormList', data)
}

// 获取form的市场活动成员的字段设置
export function getFieldShow(data) {
  return axios.post('/form/getFieldShow', data)
}

// 获取form的潜在客户的字段设置
export function getFieldDefault(data) {
  return axios.post('/form/getFieldDefault', data)
}
//保存市场云配置信息
export function saveSettingDetail(data) {
  return axios.post('/marketcloud/saveSettingDetail', data)
}
//获取市场云配置信息
export function getSettingDetail(data) {
  return axios.post('/marketcloud/getSettingDetail', data)
}
// 获取form的基本信息
export function getFormInfo(data) {
  return axios.post('/form/getFormInfo', data)
}
// 删除表单
export function deleteForm(data) {
  return axios.post('/form/deleteForm', data)
}
// 导出表单列表
export function exportFormList(data) {
  return axios.get(`/form/exportFormList`, data)
}
// 导出表单提交信息
export function exportFormInfo(data) {
  return axios.post(`/form/exportFormInfo`, data)
}
//导出汇总表单提交信息
export function exportSubmissions(data) {
  return axios.post(`/form/exportSubmissions`, data)
}
// 获取form的PageConversation信息
export function getPageConversation(data) {
  return axios.post('/form/getPageConversation', data)
}
// 获取form的SubmissionsSource信息
export function getSubmissionsSource(data) {
  return axios.post('/form/getSubmissionsSource', data)
}
//获取form详细信息
export function getFormdetail(data) {
  return axios.post('/form/getFormDetail', data)
}
//保存form信息
export function saveForm(data) {
  return axios.post('/form/saveForm', data)
}
//生成表单注册页面（获取保存Form的pageContent）
export function getFormPageContent(data) {
  return axios.post('/form/getFormPageContent', data)
}
//获取海报form的分享信息
export function getPosterShareInfo(data) {
  return axios.post('/poster/getShareInfo', data)
}
//获取员工邀约form的分享信息
export function getShareInfo(data) {
  return axios.post('/employeeinvited/getShareInfo', data)
}
//获取所有用户的邮箱信息
export function getUsersEmail(data) {
  return axios.post('/form/getUsersEmail', data)
}
//获取form的Submissions信息(收集数据)
export function getFormSubmissions(data) {
  return axios.post('/form/getFormSubmissions', data)
}
//获取form的字段提交信息
export function getFormFieldSubmissions(data) {
  return axios.post('/form/getFormFieldSubmissions', data)
}
//获取form的选项列表字段提交信息
export function getFormFieldTypeSubmissions(data) {
  return axios.post('/form/getFormFieldTypeSubmissions', data)
}
//获取form的echarts折线图信息
export function getfromData(data) {
  return axios.post('/form/getfromData', data)
}

// 查询Social用户信息
export function getScoialUserinfo(data) {
  return axios.post('/social/querySocialUser', data)
}

// 查询市场活动信息
export function getScoialMarketingActivity(data) {
  return axios.post('/social/queryCampaign', data)
}



//保存和发送Social帖子信息
export function saveScoialPoster(data) {
  return axios.post('/social/savePostInfos', data, {
    //设置请求头
    headers: { "Content-Type": "multipart/form-data" },
  })
}

//查询Social帖子列表
export function getPostInfo(data) {
  return axios.post('/social/getPostsInfo', data)
}

//查询Social帖子详情
export function getPostInfoDetail(data) {
  return axios.post('/social/getPostsInfo', data)
}

//删除Social帖子信息
export function delPostInfo(data) {
  return axios.post('/social/delPostsInfo', data)
}

//修改Social帖子
export function editPostInfo(data) {
  return axios.post('/social/editPostsInfo', data)
}

//获取社交媒体用户授权是否有效
export function userAuthorization(data) {
  return axios.post('/social/userAuthorization', data)
}
//获取领英授权url
export function authorizationUrl(data) {
  return axios.post('/social/authorizationUrl', data)
}

//获取领英授权url
export function updateUserDefault(data) {
  return axios.post('/social/updateUserDefault', data)
}

//取消Social网站账号授权
export function cancelAuthorization(data) {
  return axios.post('/social/cancelAuthorization', data)
}
// 上传文件
export function socialUploadFile(data) {
  return axios.post('/social/socialUpload', data, {
    //设置请求头
    headers: { "Content-Type": "multipart/form-data" },
  })
}
// 批量修改市场活动
export function updateBatchPostCampaign(data) {
  return axios.post('/social/updateBatchPostsCampaign', data)
}

// 批量删除帖子
export function delBatchPost(data) {
  return axios.post('/social/delBatchPosts', data)
}

// 查询Social上传的文件列表
export function querySocialFileInfo(data) {
  return axios.post('/social/querySocialFileInfo', data)
}

// 新发送Social帖子接口
export function savePostsInfo(data) {
  return axios.post('/social/savePostsInfo', data)
}

// 查询单个Social上传图片
export function chatterfile(data) {
  return axios.get('/chatterfile.action', data)
}

// 查询Social帖子类型数量
export function getPostsTypeQuantity(data) {
  return axios.post('/social/getPostsTypeQuantity', data)
}

//保存社交媒体用户和授权token
export function storeCacheAccessToken(data) {
  return axios.post('/social/storeCacheAccessToken', data)
}

//查询Social帖子点赞数分享数和分页列表
export function getPostsInfoLikesComment(data) {
  return axios.post('/social/getPostsInfoLikesComment', data)
}

//查询Social帖子点赞数分享数和分页列表
export function archiveNewOnly(data) {
  return axios.post('/social/archiveNewOnly', data)
}

// 获取社交媒体Analyze数据
export function getAnalyze(data) {
  return axios.post('/social/getAnalyze', data)
}
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

// 获取视图列表表头
export function getViewListHeader(data) {
  return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
  return axios.post('/view/list/getViewListAjax', data)
}

// 获取视图列表页面信息
export function getViewListRecently(data) {
  return axios.post('/view/list/getViewListRecently', data)
}

// 获取视图详细信息
export function getViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}

// 获取视图字段设置
export function getFieldSetup(data) {
  return axios.post('/view/getFieldSetup', data)
}

// 保存视图字段设置
export function saveFieldSetup(data) {
  return axios.post('/view/saveFieldSetup', data)
}

// 获取记录类型
export function getRecordType(data) {
  return axios.post('/batch/getRecordType', data)
}

// 获取批量新增布局信息
export function getBatchAdding(data) {
  return axios.post('/batch/getBatchInfoToAdd', data)
}

// 获取对象详细信息
export function getDetail(data) {
  return axios.post('/objectdetail/getDetail', data)
}

// 更新视图列表记录
export function updateViewListAjax(data) {
  return axios.post('/view/list/updateViewListAjax', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
  return axios.post('/lookup/getLookupData', data)
}
// 获取小程序、网站设置个案报修单所有人列表
export function getUserList(data) {
  return axios.post('/case/getUserList', data)
}
// 查找带值
export function getLookupRelatedFieldValue(data) {
  return axios.post('/lookup/getLookupRelatedFieldValue', data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}

// 查询动态布局规则
export function getDynamicLayoutListDetail(data) {
  return axios.post('/objectdetail/getDynamicLayoutListDetail', data)
}

// 删除记录
export function del(data) {
  return axios.post('/objectdetail/delete', data)
}

// 获取批量删除的数据
export function batchDelete(data) {
  return axios.post('/batch/batchDelete', data)
}

// 获取需要合并的数据
export function merge(data) {
  return axios.post('/duplication/getDataByIds', data)
}

// 主从录入表头
export function getMasterInfo(data) {
  return axios.post('/master/getMasterInfo', data)
}

// 主从录入保存
export function saveMaster(data) {
  return axios.post('/master/saveMaster', data)
}

//获取更改所有人页面信息-单条数据
export function getChangeOwner(data) {
  return axios.post('/transfer/getChangeOwner', data)
}

//获取更改所有人页面信息-批量
export function getTransfer(data) {
  return axios.post('/transfer/getTransfer', data)
}
//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}

//上传文件
export function upload(data) {
  return axios.post('/file/upload', data)
}

//上传图片文件
export function uploadImg(data) {
  return axios.post('/file/uploadImg', data)
}

//查询帖子
export function getCCChats(data) {
  return axios.post('/ccchat/microPost/getCCChats', data)
}
// 文件预览
export function viewFile(data) {
  return axios.post('file/preview', data)
}
//@用户列表
export function queryAtUsers(data) {
  return axios.post('/ccchat/microPost/queryAtUsers', data)
}
// 发表文件帖子
export function addMicroPostD(data) {
  return axios.post('/ccchat/microPost/addMicroPostD', data)
}
//投票帖子
export function changePolls(data) {
  return axios.post('/ccchat/microPost/changePolls', data)
}
// 收藏帖子
export function favoriteFeed(data) {
  return axios.post('/ccchat/microPost/favoriteFeed', data)
}
//点赞帖子
export function praiseFeed(data) {
  return axios.post('/ccchat/microPost/praiseFeed', data)
}

// 追随或取消追随,=关注，取消关注
export function setFollow(data) {
  return axios.post('/ccchat/person/setFollow', data)
}
// 是否追随或取消追随,=是否关注，取消关注
export function isFollow(data) {
  return axios.post('/ccchat/person/isFollow', data)
}

//发布帖子评论或回复
export function addMicroPostComments(data) {
  return axios.post('/ccchat/microPost/addMicroPostComments', data)
}
//  分享帖子 
export function shareMicroPost(data) {
  return axios.post('/ccchat/microPost/shareMicroPost', data)
}
//删除评论
export function removeMicroComment(data) {
  return axios.post('/ccchat/microPost/removeMicroComment', data)
}

//评论帖子 喜欢（点赞）留言或评论
export function praiseComment(data) {
  return axios.post('/ccchat/microPost/praiseComment', data)
}
//发布普通帖子
export function addMicroPost(data) {
  return axios.post('/ccchat/microPost/addMicroPost', data)
}

//获取CCChat后台设置信息
export function getSetupInfo() {
  return axios.post('/ccchat/person/getSetupInfo')
}

//单条数据转移所有人
export function updateChangeOwner(data) {
  return axios.post('/transfer/updateChangeOwner', data)
}

//批量转移所有人
export function updateTransfer(data) {
  return axios.post('/transfer/updateTransfer', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

// 获取市场活动添加成员数据
export function getAddMemberData(data) {
  return axios.post('/campaign/getAddMemberData', data)
}

// 获取市场活动成员状态选项列表值(公海池中 ButtonWrapper组件中 退回，废弃操作也用到此API)
// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 添加市场活动成员
export function addMember(data) {
  return axios.post('/campaign/addMember', data)
}

//产品详情页上价格手册条目查询
export function queryProductPricebookItems(data) {
  return axios.post('/pricebook/queryProductPricebookItems', data)
}

//产品详情页上添加标准价格
export function addStdPricebookItems(data) {
  return axios.post('/pricebook/addStdPricebookItems', data)
}

//产品详情页上添加价格手册
export function addCustomPricebookItems(data) {
  return axios.post('/pricebook/addCustomPricebookItems', data)
}

//保存价格手册
export function saveProductPricebookItem(data) {
  return axios.post('/pricebook/saveProductPricebookItem', data)
}

//删除价目表记录
export function deleteItem(data) {
  return axios.post('pricebook/deleteItem', data)
}

//产品详情页编辑价目表
export function editPricebookItem(data) {
  return axios.post('pricebook/editPricebookItem', data)
}

//是否隐藏添加价格手册按钮
export function hideAddPricebookButton(data) {
  return axios.post('pricebook/hideAddPricebookButton', data)
}

// 获取新建事件相关项
export function getDialigItem(data) {
  return axios.post('activity/getQuickActivityPage', data)
}

// 获取对象权限
export function getObjectPermission(data) {
  return axios.post('objectdetail/getObjectPermission', data)
}

// 更新列表字段宽度
export function updateViewFieldMemory(data) {
  return axios.post('view/list/updateViewFieldMemory', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
  return axios.post('objectdetail/queryMoreDataByName', data)
}

// 潜在客户热度设置信息
export function getLeadHeat(data) {
  return axios.post('leadscore/getLeadHeat', data)
}

// 获取用户界面设置
export function getUserSetup(data) {
  return axios.get('/user/getUserSetup', data)
}

// 队列
export function queryQueue(data) {
  return axios.post('/objectdetail/queryQueue', data)
}

// 更改所有人是否展示伙伴云和队列
export function checkQueueAndPartner(data) {
  return axios.post('/objectdetail/checkQueueAndPartner', data)
}

// 解除记录锁定状态
export function recordUnLocked(data) {
  return axios.post('/objectdetail/recordUnLocked', data)
}

//富文本图片上传
export function fileCKUploadImg(data) {
  return axios.post('/file/CKUploadImg', data)
}

//获取对象下公海池
export function selMarketsea(data) {
  return axios.post('/marketseaFunction/selMarketsea', data)
}



// 自定义按钮
export function openCall(data) {
  return axios.post('/openCall/common', data)
}
// 贺文娟添加的营销渠道二维码相关的接口
// 传营销渠道id，获取每个步骤的数据，去回显
export function stepOne(data) {
  return axios.post('/channel/step1', data)
}
export function stepTwo(data) {
  return axios.post('/channel/step2', data)
}
export function stepThree(data) {
  return axios.post('/channel/step3', data)
}
export function stepFour(data) {
  return axios.post('/channel/step3', data)
}
export function stepFive(data) {
  return axios.post('/channel/step5', data)
}
export function stepSix(data) {
  return axios.post('/channel/step6', data)
}
export function stepSeven(data) {
  return axios.post('/channel/step7', data)
}
// 营销渠道注册页面预览
export function channelPreview(data) {
  return axios.post('/channel/preview', data)
}
// 营销渠道保存
export function channelSave(data) {
  return axios.post('/channel/save', data)
}
//获取营销渠道列表
export function getMarketList(data) {
  return axios.post('/channel/list', data)
}

//删除营销渠道
export function delMarketList(data) {
  return axios.post('/channel/delete', data)
}

//批量删除营销渠道
export function delMoreMarketList(data) {
  return axios.post('/channel/batchdelete', data)
}

//市场营销渠道二维码
export function getQrCode(data) {
  return axios.post('/channel/qrcode', data)
}

//根据营销渠道查询活动成员
export function getMarketingActivities(data) {
  return axios.post('/channel/queryMemberByChannel', data)
}

//发送邀约码
export function sendQrCode(data) {
  return axios.post('/channel/sendQrCode', data)
}

//天眼查是否启用设置
export function isOpen(data) {
  return axios.post('/tianyancha/isOpen', data)
}

//新建事件前检查重复
export function checkEventConflict(data) {
  return axios.post('/objectInfo/checkEventConflict', data)
}

// 日程预约 初始化
export function initAllSelectOptions(data) {
  return axios.post('/appointment/initAllSelectOptions', data)
}

// 保存日程预约
export function appointmentSave(data) {
  return axios.post('appointment/save', data)
}

// 邮件查找关联日程预约
export function queryAppointments(data) {
  return axios.post('/appointment/queryAppointments', data)
}

// 保存海报信息
export function savePoster(data) {
  return axios.post('/poster/savePoster', data)
}

// 获取海报信息
export function getPosterInfo(data) {
  return axios.post('/poster/getPosterInfo', data)
}

// 获取form的列表信息
export function getPosterFormList(data) {
  return axios.post('/poster/getPosterFormList', data)
}

// 删除海报
export function deletePoster(data) {
  return axios.post('/poster/deletePoster', data)
}

// 获取员工邀约的列表
export function getEmployeeInvitedList(data) {
  return axios.post('/employeeinvited/getEmployeeInvitedList', data)
}

// 获取海报的列表
export function getPosterList(data) {
  return axios.post('/poster/getPosterList', data)
}


// 获取海报信息
export function getEmployeeInvitedInfo(data) {
  return axios.post('/employeeinvited/getEmployeeInvitedInfo', data)
}

// 保存员工邀约
export function saveEmployeeInvited(data) {
  return axios.post('/employeeinvited/saveEmployeeInvited', data)
}


// 删除员工邀约
export function deleteEmployeeInvited(data) {
  return axios.post('/employeeinvited/deleteEmployeeInvited', data)
}


// 查询项目模板
export function queryProjectTemplateList(data) {
  return axios.post('/projectTemplate/queryProjectTemplateList', data)
}

// 项目模板保存接口
export function templateSave(data) {
  return axios.post('/projectTemplateOperation/templateSave', data)
}

// 标记/取消标记为模板
export function modifyProjectTemplate(data) {
  return axios.post('/projectTemplate/modifyProjectTemplate', data)
}

// 主从录入保存税种
export function taxSave(data) {
  return axios.post('/tax/save', data)
}
// 主从录入获取税种信息
export function ListTax(data) {
  return axios.post('/tax/listTax', data)
}

// 获取产品关联的税
export function listRelatedTax(data) {
  return axios.post('/tax/listRelatedTax', data)
}
// 查询所选产品关联的价格手册条目价格
export function relatedProductPrice(data) {
  return axios.post('/master/relatedProductPrice', data)
}
// 删除税
export function deleteTax(data) {
  return axios.post('/tax/deleteTax', data)
}
// 查询主录入数据
export function masterRecordDetailInfo(data) {
  return axios.post('/master/masterRecordDetailInfo', data)
}

// 获取视图详细信息
export function GetViewInfo(data) {
  return axios.post('/view/getViewInfo', data)
}

// 搜索用户组
export function SearchGroup(data) {
  return axios.post('/view/searchGroup', data)
}

// 保存视图
export function SaveView(data) {
  return axios.post('/view/saveView', data)
}

// 删除视图
export function DeleteGroup(data) {
  return axios.post('/view/deleteView', data)
}

// 获取视图排序
export function GetSortView(data) {
  return axios.post('/view/getSortView', data)
}

// 保存视图排序
export function SaveSortView(data) {
  return axios.post('/view/saveSortView', data)
}

// 获取视图列表
export function GetViewList(data) {
  return axios.post('/view/list/getViewList', data)
}

// 获取默认视图
export function SetViewDefault(data) {
  return axios.post('/view/list/setViewDefault', data)
}

// 获取详情页标签
export function GetBrief(data) {
  return axios.post('/objectdetail/getBrief', data)
}

//获取相关列表对象信息
export function GetRelationList(data) {
  return axios.post('/objectdetail/getRelationList', data)
}

//获取相关列表数据及按钮和字段信息
export function GetDetailRelatedItems(data) {
  return axios.post('/objectdetail/getDetailRelatedItems', data)
}

//根据记录id获取标签
export function GetTagsByRecordId(data) {
  return axios.post('/tag/getTagsByRecordId', data)
}
// 获取管理标签权限
export function GetManageTagPermissio(data) {
  return axios.post('/tag/getManageTagPermissio', data)
}

// 获获取选项列表值
export function GetViewGetSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

// 获取币种信息
export function GetCurrencyInfo(data) {
  return axios.post('/currency/getCurrencyInfo', data)
}

// 详情删除接口
export function GetDelete(data) {
  return axios.post('/objectdetail/delete', data)
}

// 批量打分
export function BatchLeadScore(data) {
  return axios.post('/leadscore/batchLeadScore', data)
}

// 获取共享规则列表
export function GetSharegetShareRuleList(data) {
  return axios.post('/share/getShareRuleList', data)
}
// 搜索用户组
export function GetSearchGroup(data) {
  return axios.post('/share/searchGroup', data)
}

// 保存共享规则
export function GetShareSaveShareRule(data) {
  return axios.post('/share/saveShareRule', data)
}

// 删除共享规则
export function GetShareDeleteShareRule(data) {
  return axios.post('/share/deleteShareRule', data)
}

// 获取活动列表
export function GetActivityGetActivityList(data) {
  return axios.post('/activity/getActivityList', data)
}

// 从业务机会同步
export function GetQuoteSyncingFromOpportunity(data) {
  return axios.post('/quote/syncingFromOpportunity', data)
}

// 从业务机会同步
export function GetQuoteSyncingToOpportunity(data) {
  return axios.post('/quote/syncingToOpportunity', data)
}

// 转化潜在客户
export function GetLeadconversSaveLeadConvers(data) {
  return axios.post('/leadconvers/saveLeadConvers', data)
}

// 获取潜在客户转化页面信息
export function GetLeadconversGetLeadConvers(data) {
  return axios.post('/leadconvers/getLeadConvers', data)
}

// 获取潜在客户转化页面信息
export function leadconvers(data) {
  return axios.post('/leadconvers/getRecordTypeById', data)
}
//保存pdf到附件
export function SavePdfToFile(data) {
  return axios.post('/quote/savePdfToFile', data)
}

//获取活动快速新建页面信息
export function ActivityGetQuickActivityPage(data) {
  return axios.post('/activity/getQuickActivityPage', data)
}

// 选项卡菜单页面根据对象id获取报表列表
export function getReportListByObjId(data) {
  return axios.post('/report/getReportListByObjId', data)
}

// 获取对象信息
export function getObjectGetObjectInfo(data) {
  return axios.post('/object/getObjectInfo', data)
}

//重置列宽
export function ResettingViewFieldMemory(data) {
  return axios.post('/view/list/resettingViewFieldMemory', data)
}


// 保存标签
export function GetTagSaveTag(data) {
  return axios.post('/tag/saveTag', data)
}

// 给记录添加标签
export function GetTagAddTagRelation(data) {
  return axios.post('/tag/addTagRelation', data)
}

// 删除标签
export function GetTagDeleteTag(data) {
  return axios.post('/tag/deleteTag', data)
}

// 删除记录上的标签
export function GetTagDeleteTagRelationg(data) {
  return axios.post('/tag/deleteTagRelation', data)
}

// 根据标签id获取标签
export function GetTagGetTagById(data) {
  return axios.post('/tag/getTagById', data)
}

// 根据记录id获取标签
export function GetTagGetTagsByRecordId(data) {
  return axios.post('/tag/getTagsByRecordId', data)
}

// 搜索标签
export function GetTagSearchTags(data) {
  return axios.post('/tag/searchTags', data)
}

// 查询我的标签
export function GetTagGetMyAllTagsg(data) {
  return axios.post('/tag/getMyAllTags', data)
}

// 获取管理标签权限
export function GetTagGetManageTagPermission() {
  return axios.post('/tag/getManageTagPermission')
}
// 获取MailChimp权限
export function GetMailchimpGetMailChimpPermission(data) {
  return axios.post('/mailchimp/getMailChimpPermission', data)
}

// 获取MailChimp列表
export function GetMailchimpGetList(data) {
  return axios.post('/mailchimp/getList', data)
}

// 导入MailChimp
export function GetMailchimpExport(data) {
  return axios.post('/mailchimp/export', data)
}

// 获取视图数据条数
export function getAccountNum(data) {
  return axios.post('/mailchimp/getAccountNum', data)
}
// 获取图表列表
export function getViewChartList(data) {
  return axios.post('/viewChart/list', data)
}

// 根据对象id获取图表中的仪表板列表
export function getDashboardListByObjId(data) {
  return axios.post('/dashboard/getDashboardListByObjId', data)
}

// 保存视图图表
export function getViewChartSave(data) {
  return axios.post('/viewChart/save', data)
}

// 新增图表页
export function getViewChartNew(data) {
  return axios.post('/viewChart/new', data)
}

// 图表编辑页
export function getViewChartEdit(data) {
  return axios.post('/viewChart/edit', data)
}

// 图表展示数据接口
export function getViewChartDisplay(data) {
  return axios.post('/viewChart/display', data)
}

// 删除图表
export function deleteViewChart(data) {
  return axios.post('/viewChart/delete', data)
}
// 看板视图展示
export function GetViewKanbanDisplay(data) {
  return axios.post('/viewKanban/display', data)
}

// 看板视图设置
export function GetViewKanbanEdit(data) {
  return axios.post('/viewKanban/edit', data)
}

// 保存看板视图设置
export function GetViewKanbanSave(data) {
  return axios.post('/viewKanban/save', data)
}

// 删除看板视图
export function GetViewKanbanDelete(data) {
  return axios.post('/viewKanban/delete', data)
}

// 视图看板列表滚动查询
export function GetViewKanbanList(data) {
  return axios.post('/viewKanban/list', data)
}
// 获取视图列表 (左侧下拉菜单列表,) // 获取对象下所有可见的公海池
export function queryMarketSeaList(data) {
  return axios.post('/marketsea/queryMarketSeaList', data)
}


// 获取视图列表表头   获取公海池列表表头及按钮权限 包含按钮等,左侧名称
export function queryHeaderByMarketId(data) {
  return axios.post('/marketsea/queryHeaderByMarketId', data)
}



// 获取视图列表记录（表格数据）
export function queryDataByMarketId(data) {
  return axios.post('/marketsea/queryDataByMarketId', data)
}


// 领取与分配 
export function marketseaCollectionAndDistribution(data) {
  return axios.post('marketseaFunction/marketseaCollectionAndDistribution', data)
}

//退回与回收
export function marketseaReturnAndRecovery(data) {
  return axios.post('marketseaFunction/marketseaReturnAndRecovery', data)
}

// 获取公海池成员  分配时使用(下拉选项)
export function getMarketseaSpecMem(data) {
  return axios.post('marketseaFunction/getMarketseaSpecMem', data)
}

// 转移公海池
export function marketseaTransfer(data) {
  return axios.post('marketseaFunction/marketseaTransfer', data)
}
//废弃与恢复
export function marketseaAbandonmentAndRecovery(data) {
  return axios.post('marketseaFunction/marketseaAbandonmentAndRecovery', data)
}
// 视图列表退回（公海池）
export function getReturnSeasPool(data) {
  return axios.post('/marketseaFunction/getReturnSeasPool', data)

}
//获取管理合作伙伴的权限
export function getPartnerGetPartnerPermission(data) {
  return axios.post('/partner/getPartnerPermission', data)
}

//客户下启用禁用合作伙伴
export function getPartnerPartnerEnableAccount(data) {
  return axios.post('/partner/partnerEnableAccount', data)
}

//联系人下启用禁用合作伙伴
export function getPartnerPartnerEnableContact(data) {
  return axios.post('/partner/partnerEnableContact', data)
}

//获取所有能登录的伙伴云链接
export function getPartnerQueryAllPartnerByLogin(data) {
  return axios.post('/partner/queryAllPartnerByLogin', data)
}

//保存伙伴云用户
export function getPartnerSavePartnerUser(data) {
  return axios.post('/partner/savePartnerUser', data)
}

//编辑合作伙伴用户
export function getPartnerEditPartnerUser(data) {
  return axios.post('/partner/editPartnerUser', data)
}